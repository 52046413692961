export const Business = (props) => {
  return (
    <div id='business' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>BUSINESS</h2>
          <p>
            축적된 노하우와 기술을 바탕으로 빅데이터·데이터 분석·인공지능 플랫폼 및 데이터 분석 서비스, 클라이언트기반 솔루션 그리고 데이터 분석 파이프라인 구축 서비스 제공 등 지속적인 도전과 혁신을 통해 고객의 차별화된 신규 데이터 정보 가치를 창조.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  );
};
