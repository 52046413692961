export const History = (props) => {
  return (
    <div id='history' className='text-center'>
      <div className='container'>
        <div className='row'>
          <h2>HISTORY</h2>
          <ul class="timeline">
            <li>
              <div class="timeline-image"></div>
              <div class="timeline-panel-lf">
                <div class="timeline-heading-lf">
                  <h3>2021-2019 SI·플랫폼·솔루션</h3>
                  {/* <h4 class="subheading">Our Humble Beginnings</h4> */}
                </div>
                <div class="timeline-body">
                  <ul>
                    <li class="text-muted">전산감사솔루션 개발 공급</li>
                    <li class="text-muted">현대해상 통신표준, 통합배포, 업무푸시 및 보상 포탈시스템 구축</li>
                    <li class="text-muted">한국교육개발원 대학졸업 취업·진로 정보 제공을 위한 취업 예측 모형 개발</li>
                    <li class="text-muted">행정안전부,국가정보자원관리원 인공지능기반 적응형 보안시스템 구축 SI 참여</li>
                    <li class="text-muted">소방청 소방안전정보시스템 3차 구축 빅데이터 컨설팅</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="timeline-image"></div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h3>2018 SI·플랫폼</h3>
                </div>
                <div class="timeline-body">
                  <ul>
                    <li class="text-muted">한국데이터산업진흥원 데이터 프리존 구축 및 활용 지원</li>
                    <li class="text-muted">한국데이터산업진흥원 데이터코리아 구축</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="timeline-image"></div>
              <div class="timeline-panel-lf">
                <div class="timeline-heading">
                  <h3>2017 SI·플랫폼</h3>
                </div>
                <div class="timeline-body">
                  <ul>
                    <li class="text-muted">법무부 인공지능 기반 대화형 생활법률 지식 서비스 고도화 구축</li>
                    <li class="text-muted">국가보훈처 차세대 보훈시스템 구축</li>
                    <li class="text-muted">365mc 네트웍스 지방흡입 센서 데이터 분석 프로젝트</li>
                    <li class="text-muted">한국데이터산업진흥원 CKAN 기반 기초 빅데이터 제작 및 활용지원</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="timeline-image"></div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h3>2016 플랫폼</h3>
                </div>
                <div class="timeline-body">
                  <ul>
                    <li class="text-muted">한국데이터산업진흥원 기초 융합 빅데이터 제작 및 활용 지원</li>
                    <li class="text-muted">현대자동차 VCRM 차량 정보 모니터링 환경 구축</li>
                    <li class="text-muted">한국방송통신전파진흥원 전파정보 빅데이터 분석</li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="timeline-image"></div>
              <div class="timeline-panel-lf">
                <div class="timeline-heading">
                  <h3>2015 플랫폼</h3>
                </div>
                <div class="timeline-body">
                  <ul>
                    <li class="text-muted">중소기업기술정보진흥원 SQL을 활용한대용량 실시간 데이터 분석 플랫폼 구축</li>
                    <li class="text-muted">가온데스팅 클라우드 기반 품질 협업 플랫폼 구축</li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
