export const Platform = (props) => {
  return (
    <div id='platform' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>platform</h2>
          <p>
            공공 · 금융 · 의료 · 제조 분야에서 검증된 빅데이터 서비스 파이프라인, 인공지능, 데이터 분석, 블록체인 플랫폼 공급을 통해 고객의 차별화된 서비스를 제공하고 데이터 기반의 비즈니스 가치를 창조.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  );
};
