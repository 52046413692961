export const Recruit = (props) => {
  return (
    <div id='recruit' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>recruit</h2>
          <p>
            <span className="span-text">베이소프트</span>에서 미래를 함께 할 열정있는 인재를 모집 합니다.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
        <div className='row-btn'>
          <a
            href='https://www.saramin.co.kr/zf_user/company-info/view?csn=UjZvNzhnODV2N1lhWHJPYUtQQVBJUT09'
            className='btn btn-custom btn-lg page-scroll btn-radius'
            target='_blank'
          >
            채용공고확인
          </a>{' '}
        </div>
      </div>
    </div>
  );
};
