export const Service = (props) => {
  return (
    <div id='service' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>service</h2>
          <p>
            빅데이터 분석을 통한 고객이 필요로 하는 유의미한 데이터 서비스 제공.
          </p>
        </div>
      </div>
    </div>
  );
};
