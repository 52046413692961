import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { History } from "./components/history";
import { Areas } from "./components/areas";
import { Business } from "./components/business";
import { Position } from "./components/position";
import { Platform } from "./components/platform";
import { PlatformData } from "./components/platformData";
import { PlatformCloud } from "./components/platformCloud";
import { PlatformSpark } from "./components/platformSpark";
import { PlatformChain } from "./components/platformChain";
import { Solution } from "./components/solution";
import { SolutionFraudit } from "./components/solutionFraudit";
import { SolutionChatbot } from "./components/solutionChatbot";
import { SolutionFido } from "./components/solutionFido";
import { Service } from "./components/service";
import { ServiceUser } from "./components/serviceUser";
import { ServiceBiz } from "./components/serviceBiz";
import { Clients } from "./components/clients";
import { Contact } from "./components/contact";
import { Recruit } from "./components/recruit";


import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      
      {/* About Area */}
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Areas data={landingPageData.Areas} />
      <History data={landingPageData.History} />
      
      {/* Business Area */}
      <Business data={landingPageData.Business} />
      <Position data={landingPageData.Position} />
      
      {/* Platform Area */}
      <Platform data={landingPageData.Platform} />
      <PlatformData data={landingPageData.PlatformData} />
      <PlatformCloud data={landingPageData.PlatformCloud} />
      <PlatformSpark data={landingPageData.PlatformSpark} />
      <PlatformChain data={landingPageData.PlatformChain} />
      
      {/* Solution Area */}
      <Solution data={landingPageData.Solution} />
      <SolutionFraudit data={landingPageData.SolutionFraudit} />
      <SolutionChatbot data={landingPageData.SolutionChatbot} />
      <SolutionFido data={landingPageData.SolutionFido} />
      
      {/* Service Area */}
      <Service data={landingPageData.Service} />
      <ServiceUser data={landingPageData.ServiceUser} />
      <ServiceBiz data={landingPageData.ServiceBiz} />
      
      {/* Clients Area */}
      <Clients data={landingPageData.Clients} />

      {/* Recruit Area */}
      <Recruit data={landingPageData.Recruit} />

      {/* contact Area */}
      <Contact data={landingPageData.Contact} /> 
    </div>
  );
};

export default App;
