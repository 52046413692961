import { useState } from 'react'
import emailjs from 'emailjs-com'
import Modal from 'react-modal'
import Popup from './kakaomap'
import { GeoAltFill, X } from 'react-bootstrap-icons'

const initialState = {
  name: '',
  email: '',
  message: '',
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#fffff';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          {/* <div className='col-md-6'>
            <div className='row'>
              <div className='section-title'>
                <h2>CONTACT</h2>
                <p>
                  문의 사항에 대해 이야기 해주세요.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div> */}
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <img src="img/BEYSOFT_LOGO_redO.png" className="img-foot-logo" alt="" />{" "}
            </div>
          </div>
          <div className='col-md-4 contact-info contact-item-right'>
            <div className='contact-item'>
              <ul class="float-left">
                <li><span>상호명</span> : (주)베이소프트</li>
                <li><span>대표자</span> : 박진수</li>
                <li><span>사업자 등록번호</span> :723-86-02522</li>
                {/* <li><span>통신판매 사업자번호</span> : 2022-서울서초-1052</li> */}
                <li><span>사업장주소</span> : 서울시 서초구 방배동 451-24 현성빌딩 3층 </li>
                <li><span>대표번호</span>  : 070-4116-2876</li>
                <li><span>개인정보관리 책임자</span>  : info@beys.io</li>
              </ul>
            </div>
          </div>
          <div className='col-md-4 contact-info'>
            <div className='contact-item'>
              <ul class="float-left">
                <li className='li-last'><span>제휴사이트</span>  : <a href='https://www.fraudit.io' target='_blank'><img src="img/fraudit2.png" className="fraudit-lg" alt="" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy;BEYSOFT All Rights Reserved
          </p>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className='col-md-11'><h2 ref={(_subtitle) => (subtitle = _subtitle)}>찾아오시는길</h2></div>
        <div className='col-md-1'><X className='modal-close' onClick={closeModal} /></div>
        <Popup></Popup>
        <img src="img/map.png" className="img-map" alt="" />{" "}
      </Modal>
    </div>
  )
}