export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          {/* <h2>About As</h2> */}
          <p>
            4차 산업혁명 시대의 고객과 시장에 대한 깊은 이해를 바탕으로 검증된 플랫폼, 솔루션, SI사업을 제공하여 고객의 목적 가치를 실현하고, 데이터 기술 정보의 차별화된 가치 서비스를 창출할 수 있도록 지원합니다.
          </p>
        </div>
      </div>
    </div>
  )
}
