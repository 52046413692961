export const Solution = (props) => {
  return (
    <div id='solution' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>solution</h2>
          <p>
            공공 · 금융 · 의료 · 제조 분야에서 검증된 다양한 솔루션 공급을 통해 프로젝트의 비용과 시간을 절감하고, 지속적이고 장기적인 고객 가치 향상을 추구
          </p>
        </div>
      </div>
    </div>
  );
};
