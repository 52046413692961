export const Clients = (props) => {
  return (
    <div id='clients' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>clients</h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            {" "}
            <img src="img/clients2.png" className="img-responsive" alt="" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
