export const ServiceBiz = (props) => {
  return (
    <div id="serviceBiz">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/bizService.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{props.data ? props.data.paragraph : "loading..."}</h2>
              {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
              {/* <h3>{props.data ? props.data.paragraph : "loading..."}</h3> */}
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
